import axios from 'axios'
import qs from 'qs'
import { navigateToUrl } from 'single-spa'
import { getValidToken, getRestaurantId, tokenTypesConfig } from '@je-pc/auth'
import { TENANT } from './constants'
import logger from './logger'

const urlFormatter = (url) => {
  const restaurant_id = getRestaurantId()
  return url
    .replace('{tenant}', TENANT)
    .replace('{restaurantId}', restaurant_id)
}

const logInterceptorException = (error, interceptorType) => {
  logger.error(error, { interceptorType, exception: error })
}

const getClient = (
  baseURL = null,
  isAuthorised = true,
  tokenType = tokenTypesConfig.default
) => {
  const authorisedClient = axios.create({
    baseURL,
  })

  const getIsAuthorizedRequest = (requestConfig = {}) => {
    if (!requestConfig || typeof requestConfig !== 'object') return

    const CONFIG_IS_AUTHORIZED_OPTION = 'isAuthorized'
    return CONFIG_IS_AUTHORIZED_OPTION in requestConfig
      ? requestConfig[CONFIG_IS_AUTHORIZED_OPTION]
      : isAuthorised
  }

  authorisedClient.defaults.paramsSerializer = (params) =>
    qs.stringify(params, { arrayFormat: 'repeat' })

  // request interceptor
  authorisedClient.interceptors.request.use(
    async (config) => {
      const isAuthorisedRequest = getIsAuthorizedRequest(config)

      // Handle config with authorization headers if required
      if (isAuthorisedRequest) {
        const token = await getValidToken({ tokenType })
        if (token) {
          config.headers.common.Authorization = `Bearer ${token}`
          config.url = urlFormatter(config.url)
        }
      }

      return config
    },
    (error) => {
      logInterceptorException(error, 'request')
      return Promise.reject(error)
    }
  )

  // response interceptor
  authorisedClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalConfig = error?.config
      const errorStatusCode = error?.response?.status
      const isAuthorisedRequest = getIsAuthorizedRequest(originalConfig)
      // Handle response authorization error
      if (
        isAuthorisedRequest &&
        errorStatusCode === 401 &&
        originalConfig &&
        !originalConfig._retry
      ) {
        // Get new authentication token for config headers
        const token = await getValidToken({ forceRefresh: true, tokenType })
        originalConfig.headers['Authorization'] = `Bearer ${token}`
        // Repeat request with `_retry` flag
        originalConfig._retry = true
        return authorisedClient(originalConfig)
      }

      // Handle 500 or repeated authorization error
      if (errorStatusCode === 500 || originalConfig?._retry) {
        logInterceptorException(error, 'response')
        navigateToUrl('/error')
      }

      return Promise.reject(error)
    }
  )

  return authorisedClient
}

const decideResponse = (response, returnFullResponse) => {
  let result = returnFullResponse ? response : response.data
  return Promise.resolve(result)
}

/** ApiClient for Partner Centre SPA modules */
class ApiClient {
  /**
   * @param  {null|string} [baseUrl=null] will be prepended to `url` for each API call
   * @param  {boolean} [isAuthorised=true] allows to use authorization interceptor for API calls
   * @param  {boolean} [returnFullResponse=false] return axios response if true or only response data
   * @param  {Object} options - Additional parameters.
   * @param  {('connect'|'keycloak'} [options.tokenType=tokenTypesConfig.default] defines type of token to be used for authorised requests: original keycloak token or exchanged connect token. The default value is the default token type defined in auth module
   */
  constructor(
    baseUrl = null,
    isAuthorised = true,
    returnFullResponse = false,
    options = { tokenType: tokenTypesConfig.default }
  ) {
    this.client = getClient(baseUrl, isAuthorised, options.tokenType)
    this.returnFullResponse = returnFullResponse
  }

  get(url, conf = {}) {
    return this.client
      .get(url, conf)
      .then((response) => decideResponse(response, this.returnFullResponse))
      .catch((error) => Promise.reject(error))
  }

  delete(url, conf = {}) {
    return this.client
      .delete(url, conf)
      .then((response) => decideResponse(response, this.returnFullResponse))
      .catch((error) => Promise.reject(error))
  }

  head(url, conf = {}) {
    return this.client
      .head(url, conf)
      .then((response) => decideResponse(response, this.returnFullResponse))
      .catch((error) => Promise.reject(error))
  }

  options(url, conf = {}) {
    return this.client
      .options(url, conf)
      .then((response) => decideResponse(response, this.returnFullResponse))
      .catch((error) => Promise.reject(error))
  }

  post(url, data = {}, conf = {}) {
    return this.client
      .post(url, data, conf)
      .then((response) => decideResponse(response, this.returnFullResponse))
      .catch((error) => Promise.reject(error))
  }

  put(url, data = {}, conf = {}) {
    return this.client
      .put(url, data, conf)
      .then((response) => decideResponse(response, this.returnFullResponse))
      .catch((error) => Promise.reject(error))
  }

  patch(url, data = {}, conf = {}) {
    return this.client
      .patch(url, data, conf)
      .then((response) => decideResponse(response, this.returnFullResponse))
      .catch((error) => Promise.reject(error))
  }
}
export { ApiClient }
